import React, { useState, useEffect } from 'react'
import { Button, Grid } from '@mui/material'
import Header from '../Header/Header'
import Footer from '../Footer/footer'
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import {
    Modal,
    Box,
    TextField,
    Select,
    Tab,
    Tabs,
    FormControl,
    InputLabel,
    Typography,
    IconButton,
    AccordionSummary,
    AccordionDetails,
    MenuItem,
    InputAdornment,
    Accordion,
    FormControlLabel,
    Checkbox,
    FormGroup,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SouthRoundedIcon from '@mui/icons-material/SouthRounded';
import './Copytrade.css'
import { Bgeye, Fire, Profile } from '../../SvgCollection';
import { Link } from 'react-router-dom';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import kcs from '../../Img/coins/kcs.png'
import scr from '../../Img/coins/scr.png'
import cat from '../../Img/coins/cat.png'
import Avatar from '@mui/material/Avatar';
// import searchs from '../../Img/search.svg'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import prf from '../../Img/prf.png';
import card_chart from '../../Img/card_chart.png';
import chart_img from '../../Img/chart_img.png';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Copytrade() {

    const [value, setValue] = useState(0);

    const [show, setShow] = useState(true);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [open, setOpen] = useState(false);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));


    const handleOnclose = () => {
        setOpen(false);
    };


    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        '@media(max-width:767.98px)': {
            width: '80%',
        },
        maxWidth: 500,
        bgcolor: 'var(--mdl)',
        borderRadius: 2,
        boxShadow: 24,
        p: 3,
    };

    return (
        <div className='copytrade' >
            <Grid container sx={{ justifyContent: 'center' }}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid sx={{ mx: "auto", mb: "10px", px: 1 }} xs={12} sm={12} md={12} lg={10} xl={10}>
                    <Grid container sx={{ justifyContent: 'center' }}>
                        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box
                                sx={{ width: { md: '100%', lg: '90%' }, margin: "auto" }}
                            >
                                <h2 className='future_h' >Futures Copy Settings</h2>
                                <div className='profit_h' >Profit Sharing <div className='ylw' >10.00%</div></div>
                            </Box>
                        </Grid>

                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Box
                                className="left_b"
                                sx={{ width: { md: '100%', lg: '80%' }, margin: "auto" }}
                            >
                                {/* <h2 className='future_h' >Futures Copy Settings</h2>
                                <div className='profit_h' >Profit Sharing <div className='ylw' >10.00%</div></div> */}
                                <Box class="cP_flx" >
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='copytrade-tab tab_h'>
                                        <Tab label={<div className="display-1">
                                            Fixed Ratio
                                        </div>
                                        } {...a11yProps(0)} />
                                        <Tab label="Fixed  Amount" {...a11yProps(1)} />
                                    </Tabs>
                                </Box>
                                <CustomTabPanel value={value} index={0} class="tab_ctn" >
                                    <div>
                                        <p>* Orders will be opened in proportion to your available margin balance/lead trader's available margin balance.
                                        </p>
                                        <div className='display-2' style={{ marginBottom: "10px" }}>
                                            <InputLabel>Lock-up period</InputLabel>
                                            <InputLabel>30 Days</InputLabel>
                                        </div>
                                        <div className='display-2' >
                                            <InputLabel>Copy Amount</InputLabel>
                                            <div className='display-1'><span>Available in Spot:–USDT </span><svg onClick={() => setOpen(true)} className='cursor-pointer' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M2.12496 6.37565V4.25065H11.6875V1.77148L15.2291 5.31315L11.6875 8.85482V6.37565H2.12496ZM14.875 12.7507V10.6257H4.95829V8.14648L1.41663 11.6882L4.95829 15.2298V12.7507H14.875Z" fill="#BD8E64" />
                                            </svg>
                                            </div>
                                        </div>
                                        <TextField
                                            fullWidth
                                            // label="Amount" 
                                            type="number"
                                            sx={{ mb: 2, fieldset: { borderColor: "#d8dce1 !important" }, input: { color: 'var(--clr-font)', padding: "10px" } }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">

                                                        <div style={{ fontSize: "12px" }} >USDT</div>

                                                        <Button
                                                            size="small"
                                                            sx={{
                                                                minWidth: 'auto',
                                                                px: 1,
                                                                color: 'var(--clr-font)',
                                                                bgcolor: 'transparent',
                                                                textTransform: 'capitalize',
                                                                // texttransform: 'capitalise !important'
                                                            }}
                                                        >
                                                            Max
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                        <div className='display-2' style={{ marginBottom: "2px" }} >
                                            <InputLabel>Total Stop Loss <span className='tag_box' > Recommended </span> </InputLabel>
                                        </div>
                                        <TextField
                                            fullWidth
                                            // label="Amount" 
                                            type="number"
                                            sx={{ mb: 1, fieldset: { borderColor: "#d8dce1 !important" }, input: { color: 'var(--clr-font)', padding: "10px" } }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">

                                                        <div>% ROE</div>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <div>When the estimated margin balance of copiers reaches -- USDT, it will trigger a Stop Loss Market order to close all positions. Estimated PnL will be -- USDT.
                                            {/* <div className='red' > Stop loss cannot be modified during the lockup period.</div> */}
                                        </div>

                                        <Accordion sx={{ background: "transparent", boxShadow: "none", border: "0" }} className='accr_b' >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                sx={{ padding: 0, border: "0" }}
                                                className='accr_head'
                                            >
                                                Advanced Settings (Optional)
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ padding: 0 }} className='accr_det' >
                                                <div>
                                                    <InputLabel>Margin Mode</InputLabel>
                                                </div>
                                                <FormControl className='sel_box' fullWidth sx={{ mb: 1 }}>
                                                    <Select
                                                        // label="To" 
                                                        sx={{ padding: "0px !important", fieldset: { borderColor: "#d8dce1 !important" }, color: 'var(--clr-font)', svg: { fill: 'var(--clr-font)' } }}
                                                    >
                                                        <MenuItem value="Copy Leader Trader's Margin Mode">Copy Leader Trader's Margin Mode</MenuItem>
                                                        <MenuItem value="Fixed Mode">Fixed Mode</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <div style={{ marginBottom: "20px" }} >Your margin mode will be the same as that set by the Lead Trader.</div>

                                                <div>
                                                    <InputLabel>Leverage</InputLabel>
                                                </div>
                                                <FormControl className='sel_box' fullWidth sx={{ mb: 1, padding: "0" }}>
                                                    <Select
                                                        // label="To" 
                                                        sx={{ fieldset: { borderColor: "#d8dce1 !important" }, color: 'var(--clr-font)', svg: { fill: 'var(--clr-font)' } }}
                                                    >
                                                        <MenuItem value="Copy Leader Trader's Leverage">Copy Leader Trader's Leverage</MenuItem>
                                                        <MenuItem value="Fixed Leverage">Fixed Leverage</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <div style={{ marginBottom: "20px" }} >Your leverage will be the same as that set by the Lead Trader.</div>
                                                <div style={{ marginBottom: "10px" }}  >
                                                    <InputLabel>Position Risk</InputLabel>
                                                </div>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <div className='display-2' >
                                                            <InputLabel>Take Profit</InputLabel>
                                                        </div>
                                                        <TextField
                                                            fullWidth
                                                            // label="Amount" 
                                                            type="number"
                                                            sx={{ mb: 2, fieldset: { borderColor: "#d8dce1 !important" }, input: { color: 'var(--clr-font)', padding: "10px" } }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <div>% ROE</div>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <div className='display-2' >
                                                            <InputLabel>Stop Loss</InputLabel>
                                                        </div>
                                                        <TextField
                                                            fullWidth
                                                            // label="Amount" 
                                                            type="number"
                                                            sx={{ mb: 2, fieldset: { borderColor: "#d8dce1 !important" }, input: { color: 'var(--clr-font)', padding: "10px" } }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">

                                                                        <div>% ROE</div>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className='display-2' >
                                                            <InputLabel>Max Cost per Order</InputLabel>
                                                        </div>
                                                        <TextField
                                                            fullWidth
                                                            // label="Amount" 
                                                            type="number"
                                                            sx={{ mb: 2, fieldset: { borderColor: "#d8dce1 !important" }, input: { color: 'var(--clr-font)', padding: "10px" } }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <div>% </div>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <FormGroup className='check_b' >
                                            <FormControlLabel control={<Checkbox />} label={<>I have confirmed Profit Sharing is <span className='ylw' > 12.00% </span></>} />
                                            <FormControlLabel control={<Checkbox />} label={<>I have read and I agree to the <span className='ylw' >User Service Agreement</span></>} />
                                        </FormGroup>
                                        <button className='or_b' >Copy</button>
                                    </div>
                                </CustomTabPanel>
                                <CustomTabPanel value={value} index={1} class="tab_ctn">
                                    <div>
                                        <p>* Each order will be opened at a fixed margin amount (cost per order).</p>
                                        <div className='display-2' style={{ marginBottom: "10px" }}>
                                            <InputLabel>Lock-up period</InputLabel>
                                            <InputLabel>30 Days</InputLabel>
                                        </div>
                                        <div className='display-2' >
                                            <InputLabel>Cost Per Order</InputLabel>
                                        </div>
                                        <TextField
                                            fullWidth
                                            // label="Amount" 
                                            type="number"
                                            sx={{ mb: 2, fieldset: { borderColor: "#d8dce1 !important" }, input: { color: 'var(--clr-font)', padding: "10px" } }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">

                                                        <div>USDT</div>

                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <div className='display-2' >
                                            <InputLabel>Copy Amount</InputLabel>
                                            <div className='display-1'><span>Available in Spot:–USDT </span><svg onClick={() => setOpen(true)} className='cursor-pointer' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M2.12496 6.37565V4.25065H11.6875V1.77148L15.2291 5.31315L11.6875 8.85482V6.37565H2.12496ZM14.875 12.7507V10.6257H4.95829V8.14648L1.41663 11.6882L4.95829 15.2298V12.7507H14.875Z" fill="#BD8E64" />
                                            </svg>
                                            </div>

                                        </div>
                                        <TextField
                                            fullWidth
                                            // label="Amount" 
                                            type="number"
                                            sx={{ mb: 2, fieldset: { borderColor: "#d8dce1 !important" }, input: { color: 'var(--clr-font)', padding: "10px" } }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">

                                                        <div>USDT</div>

                                                        <Button
                                                            size="small"
                                                            sx={{
                                                                minWidth: 'auto',
                                                                px: 1,
                                                                color: 'var(--clr-font)',
                                                                bgcolor: 'transparent',
                                                                textTransform: 'capitalize',
                                                                // texttransform: 'capitalise !important'
                                                            }}
                                                        >
                                                            Max
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <div className='display-2' style={{ marginBottom: "2px" }} >
                                            <InputLabel>Total Stop Loss <span className='tag_box' > Recommended </span> </InputLabel>
                                        </div>
                                        <TextField
                                            fullWidth
                                            // label="Amount" 
                                            type="number"
                                            sx={{ mb: 2, fieldset: { borderColor: "#d8dce1 !important" }, input: { color: 'var(--clr-font)', padding: "10px" } }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">

                                                        <div>% ROE</div>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <div>When the estimated margin balance of copiers reaches -- USDT, it will trigger a Stop Loss Market order to close all positions. Estimated PnL will be -- USDT.
                                            {/* <div className='red' > Stop loss cannot be modified during the lockup period.</div> */}
                                        </div>

                                        <Accordion sx={{ background: "transparent", boxShadow: "none", border: "0" }} className='accr_b' >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                                sx={{ padding: 0, border: "0" }}
                                                className='accr_head'
                                            >
                                                Advanced Settings (Optional)
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ padding: 0 }} className='accr_det' >
                                                <div>
                                                    <InputLabel>Margin Mode</InputLabel>
                                                </div>
                                                <FormControl className='sel_box' fullWidth sx={{ mb: 1 }}>
                                                    <Select
                                                        // label="To" 
                                                        sx={{ padding: "0px !important", fieldset: { borderColor: "#d8dce1 !important" }, color: 'var(--clr-font)', svg: { fill: 'var(--clr-font)' } }}
                                                    >
                                                        <MenuItem value="Copy Leader Trader's Margin Mode">Copy Leader Trader's Margin Mode</MenuItem>
                                                        <MenuItem value="Fixed Mode">Fixed Mode</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <div style={{ marginBottom: "20px" }} >Your margin mode will be the same as that set by the Lead Trader.</div>
                                                <div>
                                                    <InputLabel>Leverage</InputLabel>
                                                </div>
                                                <FormControl className='sel_box' fullWidth sx={{ mb: 1 }}>
                                                    <Select
                                                        // label="To" 
                                                        sx={{ padding: "0px !important", fieldset: { borderColor: "#d8dce1 !important" }, color: 'var(--clr-font)', svg: { fill: 'var(--clr-font)' } }}
                                                    >
                                                        <MenuItem value="Copy Leader Trader's Leverage">Copy Leader Trader's Leverage</MenuItem>
                                                        <MenuItem value="Fixed Leverage">Fixed Leverage</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <div style={{ marginBottom: "20px" }} >Your leverage will be the same as that set by the Lead Trader.</div>
                                                <div style={{ marginBottom: "10px" }}  >
                                                    <InputLabel>Position Risk</InputLabel>
                                                </div>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <div className='display-2' >
                                                            <InputLabel>Take Profit</InputLabel>
                                                        </div>
                                                        <TextField
                                                            fullWidth
                                                            // label="Amount" 
                                                            type="number"
                                                            sx={{ mb: 2, fieldset: { borderColor: "#d8dce1 !important" }, input: { color: 'var(--clr-font)', padding: "10px" } }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">

                                                                        <div>% ROE</div>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                        <div className='display-2' >
                                                            <InputLabel>Stop Loss</InputLabel>
                                                        </div>
                                                        <TextField
                                                            fullWidth
                                                            // label="Amount" 
                                                            type="number"
                                                            sx={{ mb: 2, fieldset: { borderColor: "#d8dce1 !important" }, input: { color: 'var(--clr-font)', padding: "10px" } }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">

                                                                        <div>% ROE</div>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className='display-2' >
                                                            <InputLabel>Max Cost per Order</InputLabel>
                                                        </div>
                                                        <TextField
                                                            fullWidth
                                                            // label="Amount" 
                                                            type="number"
                                                            sx={{ mb: 2, fieldset: { borderColor: "#d8dce1 !important" }, input: { color: 'var(--clr-font)', padding: "10px" } }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <div>% </div>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        <FormGroup className='check_b' >
                                            <FormControlLabel control={<Checkbox />} label={<>I have confirmed Profit Sharing is <span className='ylw' > 12.00% </span></>} />
                                            <FormControlLabel control={<Checkbox />} label={<>I have read and I agree to the{' '}<span className="ylw">User Service Agreement</span></>} />
                                        </FormGroup>
                                        <button className='or_b' >Copy</button>
                                    </div>
                                </CustomTabPanel>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Box
                                sx={{ width: { md: '100%', lg: '80%' }, margin: "auto" }}
                            >
                                <div className="prf_box">
                                    <div className='p_flx'>
                                        <img src={prf} />
                                        <h2>Long-term benefits No.2</h2>
                                    </div>
                                    <div className='c_order' >Copy orders starting from 100u, estimate the ratio! The rest is just waiting patiently.</div>
                                    <div className="c_box">
                                        <div>
                                            <span>7 Days PNL (USDT)</span>
                                            <h4>+43.86</h4>
                                            <div>ROI +2.82%</div>
                                        </div>
                                        <div>
                                            <img src={chart_img} />
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Grid>
                    </Grid >
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Footer />
                </Grid>
            </Grid>



            <Modal open={open} >
                <Box sx={modalStyle}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                        <Typography variant="h6" sx={{ fontWeight: 600, color: 'var(--clr-font)' }}>
                            Transfer
                        </Typography>
                        <IconButton onClick={handleOnclose} size="small">
                            <CloseIcon size={20} sx={{ fill: 'var(--clr-font)' }} />
                        </IconButton>
                    </Box>
                    <div className='bg_tran' >
                        <FormControl className='display-1' fullWidth
                            sx={{ mb: 0, flexDirection: "row", padding: "0px !important", border: "1px solid #d8dce1 !important", borderRadius: "5px" }}
                        >
                            <div className='display-1 ico' style={{ minWidth: "70px", paddingLeft: "10px" }} >
                                <svg width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1iztezc"><path fillRule="evenodd" clipRule="evenodd" d="M15 3.5a5.502 5.502 0 00-5.302 4.032 7.502 7.502 0 016.77 6.77A5.502 5.502 0 0015 3.5zM14.5 15a5.5 5.5 0 10-11 0 5.5 5.5 0 0011 0zm-8 0L9 17.5l2.5-2.5L9 12.5 6.5 15zM9 4H4v5l5-5zm11 16h-5l5-5v5z" fill="currentColor"></path></svg>
                                <span>From</span>
                            </div>
                            <Select
                                className='sel_box'
                                sx={{ width: "100%", fieldset: { border: 'none' }, color: 'var(--clr-font)', svg: { fill: 'var(--clr-font)' } }}
                            >
                                <MenuItem value="MAIN">Fiat & Spot</MenuItem>
                                <MenuItem value="MARGIN">Cross Margin</MenuItem>
                                {/* <MenuItem value="ISOLATEDMARGIN">Isolated Margin</MenuItem> */}
                                <MenuItem value="UMFUTURE">USDⓈ-M Futures</MenuItem>
                                <MenuItem value="CMFUTURE">COIN-M Futures</MenuItem>
                                <MenuItem value="FUNDING">Funding</MenuItem>
                            </Select>
                        </FormControl>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2, width: "100%" }}>
                            <IconButton sx={{ py: 0 }} >
                                <SouthRoundedIcon size={8} sx={{ fill: 'var(--clr-font)' }} />
                            </IconButton>
                            <IconButton sx={{ py: 0 }} >
                                <ImportExportIcon size={18} sx={{ fill: 'var(--clr-font)' }} />
                            </IconButton>
                        </Box>
                        <FormControl className='display-1' fullWidth
                            sx={{ mb: 0, flexDirection: "row", padding: "0px !important", border: "1px solid #d8dce1 !important", borderRadius: "5px" }}
                        >
                            <div className='display-1 ico' style={{ minWidth: "70px", paddingLeft: "10px" }} >
                                <svg width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" class="css-1iztezc"><path d="M14.25 7.764L12.733 9.28l1.517 1.516 1.517-1.516-1.517-1.517z" fill="currentColor"></path><path fillRule="evenodd" clipRule="evenodd" d="M20 3H8.5v6H4v7.5A3.5 3.5 0 007.5 20H16a4 4 0 004-4V3zM6.5 11.5h2v5a1 1 0 11-2 0v-5zm7.75 1.03a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5zm3.25 2v2.5H11v-2.5h6.5z" fill="currentColor"></path></svg>
                                <span>To</span>
                            </div>
                            <Select
                                className='sel_box'
                                sx={{ width: "100%", fieldset: { border: 'none' }, color: 'var(--clr-font)', svg: { fill: 'var(--clr-font)' } }}
                            >
                                <MenuItem value="MAIN">Flat & Spot</MenuItem>
                                <MenuItem value="MARGIN">Cross Margin</MenuItem>
                                {/* <MenuItem value="ISOLATEDMARGIN">Isolated Margin</MenuItem> */}
                                <MenuItem value="UMFUTURE">USDⓈ-M Futures</MenuItem>
                                <MenuItem value="CMFUTURE">COIN-M Futures</MenuItem>
                                <MenuItem value="FUNDING">Funding</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div>
                        <InputLabel>Coin</InputLabel>
                    </div>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        {/* <InputLabel>To</InputLabel> */}
                        <Select
                            className='sel_box'
                            sx={{ padding: "0px !important", fieldset: { borderColor: "#d8dce1 !important" }, color: 'var(--clr-font)', svg: { fill: 'var(--clr-font)' } }}
                        >


                            <MenuItem value="funding">BTC</MenuItem>
                            <MenuItem value="margin">ETH</MenuItem>
                        </Select>
                    </FormControl>
                    <div className='display-2' style={{ flexWrap: "wrap" }} >
                        <InputLabel>Amount</InputLabel>
                        <div>0.00000000 available / 0.00000000 in order</div>
                    </div>
                    <TextField
                        fullWidth
                        // label="Amount" 
                        type="number"
                        sx={{ mb: 2, fieldset: { borderColor: "#d8dce1 !important" }, input: { color: 'var(--clr-font)', padding: "10px" } }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        size="small"
                                        sx={{
                                            minWidth: 'auto',
                                            px: 1,
                                            color: 'var(--clr-font)',
                                            bgcolor: 'transparent',
                                            textTransform: 'capitalize',
                                            // texttransform: 'capitalise !important'
                                        }}
                                    >
                                        Max
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />



                    <Button
                        fullWidth
                        variant="contained"
                        sx={{
                            py: 0.8,
                            bgcolor: '#ff7028 !important',
                            color: '#fff',
                            textTransform: 'capitalize',

                        }}
                    >
                        Confirm
                    </Button>
                </Box>
            </Modal>
        </div >
    )
}

export default Copytrade
