import { Button, Grid } from '@mui/material'
import React, { useState, useEffect } from 'react'

// import axios from 'axios';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import nodata from '../../Img/thankyou.gif'

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Btcoin from '../../Img/btcoin.png'
import { Drop, Drop2, Info, M1, M2, M3, Msg, Trading, Wifi } from '../../SvgCollection';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import { useTheme, useMediaQuery } from '@mui/material';
import usercalls from "../../Axios";

import AppBar from '@mui/material/AppBar';


import Toolbar from '@mui/material/Toolbar';

import Drawer from '@mui/material/Drawer';
import { useNavigate, useParams } from 'react-router-dom';
import consts from '../../constant';
import { toast, ToastContainer } from 'react-toastify';
import SpTab from './SpTab';
import Transfer from './Transfer';


const style = {
    position: 'absolute',
    top: '50%',
    left: '0%',  // Start from the left side of the screen
    transform: 'translateY(-50%)', // Center vertically
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function TradeRight({ prices, setHistoryStatus, balanceStatus }) {


    const navigate = useNavigate()
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const mobilemd = useMediaQuery(theme.breakpoints.up('md'));

    const [value2, setValue2] = useState(0);

    const handleChange2 = (event, newValue) => {
        setValue2(newValue);
    };

    const { id } = useParams()

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const path = usercalls()

    const [value3, setValue3] = useState(0);

    const handleChange3 = (event, newValue) => {
        setSide("LIMIT")
        setValue4(0)
        setValue3(newValue);
        setOrderPrice("")
        setQuantity("")
        setTotal("")
    };


    const [value4, setValue4] = useState(0);

    const [side, setSide] = useState("LIMIT")

    const handleChange4 = (event, newValue) => {
        var sides = ["LIMIT", "MARKET"]
        setSide(sides[newValue])
        setValue4(newValue);
        setQuantity("")
        setTotal("")
        setOrderPrice("")
    };

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open, val) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setValue3(val)

        setState({ ...state, [anchor]: open });
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };



    const [orderPrice, setOrderPrice] = useState()
    const [quantity, setQuantity] = useState()

    const [balanceBuy, setBalanceBuy] = useState(0)
    const [balanceSell, setBalanceSell] = useState(0)

    const getBalance = async () => {
        try {
            // const url = `${consts.BackendUrl}/wallet/getWallet`
            const url = `${consts.BackendUrl}/wallet/OwnWalletGet`
            const payload = {
                pair: id
            }
            const response = await path.postCall({ url, payload });
            const result = await response.json();
            if (result?.success) {
                for(var i = 0; i < result.result.length; i++) {
                    if(result.result[i].symbol == id?.split("_")[1]) {
                        setBalanceBuy(result?.result[i]?.wallet?.spot_balance)
                    } else if(result.result[i].symbol == id.split("_")[0]) {
                        setBalanceSell(result?.result[i]?.wallet?.spot_balance)
                    }
                }
                // setBalanceBuy(Number(result?.result?.quoteCoin?.balance)?.toFixed(4))
                // setBalanceSell(Number(result?.result?.baseCoin?.balance)?.toFixed(4))
            }
        } catch (error) {
            console.log("🚀 ~ getBalance ~ error:", error)
        }
    }

    useEffect(() => {
        if (id) {
            getBalance()
        }
    }, [id, balanceStatus])

    useEffect(() => {
        // if (prices?.length > 0) {
        //     priceCalc(Number(prices[0])?.toFixed(4), Number(prices[1])?.toFixed(4))
        //     setOrderPrice(Number(prices[0])?.toFixed(4))
        //     setQuantity(Number(prices[1])?.toFixed(4))
        // }
        if (prices) {
            priceCalc(Number(prices?._id)?.toFixed(4), Number(prices?.volume)?.toFixed(4))
            setOrderPrice(Number(prices?._id)?.toFixed(4))
            setQuantity(Number(prices?.volume)?.toFixed(4))
        }
    }, [prices])

    const placeOrderTrade = async (type) => {
        try {
            if (side == 'LIMIT' && (!orderPrice || !quantity)) {
                toast.error("Please Enter Price and Quantity")
            }
            else if (!quantity) {
                toast.error("Please Enter Quantity")
            }
            else if (type == "BUY" && balanceBuy == 0) {
                toast.error("Insufficient Balance")
            } else if (type == "SELL" && balanceSell == 0) {
                toast.error("Insufficient Balance")
            }
            else {
                var payload = {
                    symbol: id,
                    type: side.toLocaleLowerCase(),
                    price: orderPrice,
                    quantity: quantity,
                    side: type.toLocaleLowerCase(),
                }
                // const url = `${consts.BackendUrl}/binanceTrade/trade`
                const url = `${consts.BackendUrl}/ownTrade/placespotorder`
                const response = await path.postCall({ url, payload });
                const result = await response.json();
                if (result?.success) {
                    toast.success(result?.message)
                    setQuantity("")
                    setTotal("")
                    setOrderPrice("")
                    getBalance()
                    setHistoryStatus(Math.floor(Math.random() * 90) + 10)
                } else {
                    toast.error(result?.message)
                    if (result?.message == "Please Verify KYC") {
                        setTimeout(() => {
                            navigate("/profile")
                        }, 1000);
                    } else if (result?.message == "Wallet Not Found") {
                        setTimeout(() => {
                            navigate("/wallet")
                        }, 1000);
                    }
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            if (error?.response?.data?.message == "Please Verify KYC") {
                navigate("/profile")
            }
            console.log("🚀 ~ placeOrderTrade ~ error:", error)
        }
    }

    const [total, setTotal] = useState(0)
    const [tot, setTot] = useState("false")


    const setPercent = async (per, type) => {
        setTot("true")
        try {
            if (type == "BUY") {
                if (side == "LIMIT") {
                    var pers = ((balanceBuy * per) / 100)
                    setTotal((pers).toFixed(4))
                    setQuantity((pers / orderPrice)?.toFixed(4))
                } else {
                    setQuantity(((balanceBuy * per) / 100)?.toFixed(4))
                }
            } else {
                if (side == "LIMIT") {
                    var pers = ((balanceSell * per) / 100)
                    setQuantity((pers).toFixed(4))
                    setTotal((pers * orderPrice)?.toFixed(4))
                } else {
                    setQuantity(((balanceSell * per) / 100)?.toFixed(4))
                }
            }
        } catch (error) {
            console.log("🚀 ~ setPercent ~ error:", error)
        }
    }

    const priceCalc = async (orderPrice, quantity) => {
        try {
            setTotal((Number(orderPrice) * Number(quantity))?.toFixed(4))
        } catch (error) {
            console.log("🚀 ~ priceCalc ~ error:", error)
        }
    }


    const placeOrder = (
        <>
            <Box  >
                {!mobilemd && <div className="text-right">
                    <HighlightOffIcon sx={{ fill: 'var(--clr-font)', cursor: 'pointer' }} onClick={(() => { setState({ bottom: false }) })} />
                </div>}
                <div className='display-2'>
                    <Tabs value={value2} onChange={handleChange2} aria-label="basic tabs example" className='reUp mob'>
                        <Tab label="Place Order" {...a11yProps(0)} />

                    </Tabs>
                    <Transfer />
                </div>

            </Box>
            <CustomTabPanel value={value2} index={0} className='spot-tab mt-10'>
                <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <SpTab />
                        <Box sx={{ width: '100%' }}>
                            {mobilemd && <Box className='mt-10'>
                                <Tabs value={value3} onChange={handleChange3} aria-label="basic tabs example" className='buy-sell-tabs'>
                                    <Tab label="Buy" {...a11yProps(0)} className='buy' />
                                    <Tab label="Sell" {...a11yProps(1)} className='sell' />

                                </Tabs>
                            </Box>}
                            <CustomTabPanel value={value3} index={0}>
                                <Grid container>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box sx={{ width: '100%' }} className='mt-10'>
                                            <Box>
                                                <Tabs value={value4} onChange={handleChange4} aria-label="basic tabs example" className='tabs-trade'>
                                                    <Tab label="Limit" {...a11yProps(0)} />
                                                    <Tab label="Market" {...a11yProps(1)} />
                                                    {/* <Tab label="Advanced Limit" {...a11yProps(2)} /> */}
                                                </Tabs>
                                            </Box>
                                            <CustomTabPanel value={value4} index={0}>
                                                <Grid container>
                                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className="display-2 mt-10">
                                                            <div className="aval">
                                                                Available
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {balanceBuy} {id?.split("_")[1]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                placeholder='Order Price'
                                                                value={orderPrice}
                                                                onChange={(e) => {
                                                                    priceCalc(e.target.value, quantity)
                                                                    setOrderPrice(e.target.value)
                                                                }}
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[0]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                value={quantity}
                                                                onChange={(e) => {
                                                                    priceCalc(orderPrice, e.target.value)
                                                                    setQuantity(e.target.value)
                                                                }}
                                                                placeholder='Quantity'
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                value={total}
                                                                placeholder='Order Value'
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <div class="mydict">
                                                            <div>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(25, "BUY") }} >25%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(50, "BUY") }}>50%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(75, "BUY") }}>75%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(100, "BUY") }}>100%</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        {/* <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                                        <OutlinedInput
                                                                            id="outlined-adornment-weight"
                                                                            endAdornment={<InputAdornment position="end" className='adroment'>USDT</InputAdornment>}
                                                                            aria-describedby="outlined-weight-helper-text"
                                                                            inputProps={{
                                                                                'aria-label': 'weight',
                                                                            }}
                                                                            placeholder='Volume'
                                                                            type="number"
                                                                        />
                                                                    </FormControl> */}

                                                        {/* <div className="display-2 mt-20">
                                                                        <div className="aval">
                                                                            Advanced:
                                                                        </div>
                                                                        <div className="display-1 usd">

                                                                            <div className="">
                                                                                Settings
                                                                            </div>


                                                                        </div>
                                                                    </div> */}

                                                        <div className="buy-btn mt-30">
                                                            {
                                                                window?.localStorage.getItem('Rikosta') ?
                                                                    <Button variant='contained' onClick={() => { placeOrderTrade("BUY") }}  >Buy</Button>
                                                                    :
                                                                    <Button variant='contained' onClick={() => { navigate('/login') }}  >Login/Signup</Button>
                                                            }

                                                        </div>
                                                    </Grid>

                                                </Grid>
                                            </CustomTabPanel>
                                            <CustomTabPanel value={value4} index={1}>
                                                <Grid container>
                                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className="display-2">
                                                            <div className="aval">
                                                                Available
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {balanceBuy} {id?.split("_")[1]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                // endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[0]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                placeholder='Best Market Price'
                                                                disabled
                                                            />
                                                        </FormControl>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                value={quantity}
                                                                onChange={(e) => { setQuantity(e.target.value) }}
                                                                placeholder='Amount'
                                                                type="number"
                                                            />
                                                        </FormControl>


                                                        <div class="mydict">
                                                            <div>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(25, "BUY") }}  >25%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(50, "BUY") }}  >50%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(75, "BUY") }}  >75%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(100, "BUY") }}  >100%</span>
                                                                </label>

                                                            </div>
                                                        </div>
                                                        <div className="buy-btn mt-30">
                                                            {
                                                                window?.localStorage.getItem('Rikosta') ?
                                                                    <Button variant='contained' onClick={() => { placeOrderTrade("BUY") }}  >Buy</Button>
                                                                    :
                                                                    <Button variant='contained' onClick={() => { navigate('/login') }}  >Login/Signup</Button>
                                                            }
                                                        </div>
                                                    </Grid>

                                                </Grid>
                                            </CustomTabPanel>

                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                            <CustomTabPanel value={value3} index={1}>
                                <Grid container>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box sx={{ width: '100%' }} className='mt-10'>
                                            <Box>
                                                <Tabs value={value4} onChange={handleChange4} aria-label="basic tabs example" className='tabs-trade'>
                                                    <Tab label="Limit" {...a11yProps(0)} />
                                                    <Tab label="Market" {...a11yProps(1)} />
                                                    {/* <Tab label="Advanced Limit" {...a11yProps(2)} /> */}
                                                </Tabs>
                                            </Box>
                                            <CustomTabPanel value={value4} index={0}>
                                                <Grid container>
                                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className="display-2 mt-10">
                                                            <div className="aval">
                                                                Available
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {balanceSell} {id?.split("_")[0]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                value={orderPrice}
                                                                onChange={(e) => {
                                                                    priceCalc(e.target.value, quantity)
                                                                    setOrderPrice(e.target.value)
                                                                }}
                                                                placeholder='Order Price'
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[0]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                value={quantity}
                                                                onChange={(e) => {
                                                                    priceCalc(orderPrice, e.target.value)
                                                                    setQuantity(e.target.value)
                                                                }}
                                                                placeholder='Quantity'
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                value={total}
                                                                placeholder='Order Value'
                                                                type="number"
                                                            />
                                                        </FormControl>


                                                        <div class="mydict">
                                                            <div>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(25, "SELL") }}>25%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(50, "SELL") }}>50%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(75, "SELL") }}>75%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(100, "SELL") }}>100%</span>
                                                                </label>

                                                            </div>
                                                        </div>

                                                        {/* <div className="display-2 mt-20">
                                                                        <div className="aval">
                                                                            Advanced:
                                                                        </div>
                                                                        <div className="display-1 usd">

                                                                            <div className="">
                                                                                Settings
                                                                            </div>


                                                                        </div>
                                                                    </div> */}

                                                        <div className="sell-btn mt-30">
                                                            {
                                                                window?.localStorage.getItem('Rikosta') ?
                                                                    <Button variant='contained' onClick={() => { placeOrderTrade("SELL") }}  >Sell</Button>
                                                                    :
                                                                    <Button variant='contained' onClick={() => { navigate('/login') }}  >Login/Signup</Button>
                                                            }
                                                        </div>
                                                    </Grid>

                                                </Grid>
                                            </CustomTabPanel>
                                            <CustomTabPanel value={value4} index={1}>
                                                <Grid container>
                                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <div className="display-2">
                                                            <div className="aval">
                                                                Available
                                                            </div>
                                                            <div className="display-1 usd">
                                                                <div className="">
                                                                    {balanceSell} {id?.split("_")[0]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                // endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[0]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                placeholder='Best Market Price'
                                                                // type="number"
                                                                disabled
                                                            />
                                                        </FormControl>
                                                        <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                                            <OutlinedInput
                                                                id="outlined-adornment-weight"
                                                                endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[0]}</InputAdornment>}
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'weight',
                                                                }}
                                                                value={quantity}
                                                                onChange={(e) => { setQuantity(e.target.value) }}
                                                                placeholder='Amount'
                                                                type="number"
                                                            />
                                                        </FormControl>
                                                        <div class="mydict">
                                                            <div>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(25, "SELL") }}>25%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(50, "SELL") }}>50%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(75, "SELL") }}>75%</span>
                                                                </label>
                                                                <label>
                                                                    <input type="radio" name="radio" />
                                                                    <span onClick={() => { setPercent(100, "SELL") }}>100%</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="sell-btn mt-30">
                                                            {
                                                                window?.localStorage.getItem('Rikosta') ?
                                                                    <Button variant='contained' onClick={() => { placeOrderTrade("SELL") }}  >Sell</Button>
                                                                    :
                                                                    <Button variant='contained' onClick={() => { navigate('/login') }}  >Login/Signup</Button>
                                                            }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CustomTabPanel>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>

                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

                    </Grid>
                </Grid>
            </CustomTabPanel>

        </>
    )
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, padding: !mobilemd ? '15px' : '' }}
            role="presentation"
        >
            {placeOrder}
        </Box>
    );

    return (
        <div className='trade-left'>
            <ToastContainer />
            <Grid container>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={!mobilemd && { display: 'none' }}>
                        {placeOrder}
                    </Box>

                </Grid>
            </Grid >

            {!mobilemd && <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, }}>
                <Toolbar sx={{ justifyContent: 'space-evenly', }}>

                    {['bottom'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <div className="buy-h-btn display-2" style={{ width: '100%' }}>
                                <Button variant='contained' onClick={toggleDrawer(anchor, true, 0)}>{'Buy'}</Button>
                            </div>

                            <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                // onClose={toggleDrawer(anchor, false)}
                                className='drawer-bottom'
                            >
                                {list(anchor)}
                            </Drawer>
                        </React.Fragment>
                    ))}


                    {['bottom'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <div className="sell-h-btn display-2" style={{ width: '100%' }}>

                                <Button variant='contained' onClick={toggleDrawer(anchor, true, 1)}>{'Sell'}</Button>
                            </div>

                            <Drawer
                                anchor={anchor}
                                open={state[anchor]}
                                // onClose={toggleDrawer(anchor, false)}
                                className='drawer-bottom'
                            >
                                {list(anchor)}
                            </Drawer>
                        </React.Fragment>
                    ))}

                </Toolbar>
            </AppBar>}


        </div >
    )
}

export default TradeRight
