import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import Header from "../Header/Header";
import "./Support.scss";
import Box from "@mui/material/Box";
import WidgetsIcon from '@mui/icons-material/Widgets';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Avatar from '@mui/material/Avatar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Stack from '@mui/material/Stack';
import './Support.scss'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "var(--support)",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  '@media(max-width:575.98px)': {
    width: '80%'
  }
};

const Support = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const [chat, setChat] = useState([
    { name: 'Sankar', noftify: 'Hi da How are you doing long time no see', img: 'https://imgs.search.brave.com/Qs-ZZa0KmMGuWoIEVpSaBhcSGFZTQ_t1QNNQlCmqrsU/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMuc2ltcGxlc3dh/cC5pby9pbWFnZXMv/Y3VycmVuY2llcy1s/b2dvL2V0aC5zdmc' },
    { name: 'Barath', noftify: 'Sankar Eod Podra', img: 'https://imgs.search.brave.com/Qs-ZZa0KmMGuWoIEVpSaBhcSGFZTQ_t1QNNQlCmqrsU/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMuc2ltcGxlc3dh/cC5pby9pbWFnZXMv/Y3VycmVuY2llcy1s/b2dvL2V0aC5zdmc' },
    { name: 'Priya', noftify: 'Saami Ac ah konjam kammi pannuda', img: 'https://imgs.search.brave.com/Qs-ZZa0KmMGuWoIEVpSaBhcSGFZTQ_t1QNNQlCmqrsU/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMuc2ltcGxlc3dh/cC5pby9pbWFnZXMv/Y3VycmVuY2llcy1s/b2dvL2V0aC5zdmc' },
    { name: 'Gowtham', noftify: 'da figma unnaku send panniten', img: 'https://imgs.search.brave.com/Qs-ZZa0KmMGuWoIEVpSaBhcSGFZTQ_t1QNNQlCmqrsU/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMuc2ltcGxlc3dh/cC5pby9pbWFnZXMv/Y3VycmVuY2llcy1s/b2dvL2V0aC5zdmc' },
    { name: 'Lucky', noftify: 'Bharath kumar! inga vaa!!', img: 'https://imgs.search.brave.com/Qs-ZZa0KmMGuWoIEVpSaBhcSGFZTQ_t1QNNQlCmqrsU/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMuc2ltcGxlc3dh/cC5pby9pbWFnZXMv/Y3VycmVuY2llcy1s/b2dvL2V0aC5zdmc' },
    { name: 'Vignesh', noftify: 'Hi da How are you doing long time no see', img: 'https://imgs.search.brave.com/Qs-ZZa0KmMGuWoIEVpSaBhcSGFZTQ_t1QNNQlCmqrsU/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMuc2ltcGxlc3dh/cC5pby9pbWFnZXMv/Y3VycmVuY2llcy1s/b2dvL2V0aC5zdmc' },
    { name: 'Ramesh', noftify: 'Bro Dreamster Merge pannitingala', img: 'https://imgs.search.brave.com/Qs-ZZa0KmMGuWoIEVpSaBhcSGFZTQ_t1QNNQlCmqrsU/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMuc2ltcGxlc3dh/cC5pby9pbWFnZXMv/Y3VycmVuY2llcy1s/b2dvL2V0aC5zdmc' },
    { name: 'Arun', noftify: 'Hi da How are you doing long time no see', img: 'https://imgs.search.brave.com/Qs-ZZa0KmMGuWoIEVpSaBhcSGFZTQ_t1QNNQlCmqrsU/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMuc2ltcGxlc3dh/cC5pby9pbWFnZXMv/Y3VycmVuY2llcy1s/b2dvL2V0aC5zdmc' },
  ])

  //personal chat

  const [perChat, setPerChat] = useState([])

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [search, setSearch] = useState()

  const [message, setMessage] = useState([]);


  const [updated, setUpdated] = useState('');

  const [movileView, setMovileView] = useState(false)



  const handleChangeSend = (event) => {
    setUpdated(event.target.value);

  };

  const handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleClickSend();
    }
  };


  const handleClickSend = (e) => {
    // 👇 "message" stores input field value

    if (updated?.trim() !== '') {
      setMessage([...message, updated])
      setUpdated('')
    }
  };

  const handleDeleteTodo = (index) => {
    const newTodos = [...message];
    newTodos.splice(index, 1);
    setMessage(newTodos);
  };

  //image upload

  const [imageUrl, setImageUrl] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    console.log(reader, "result of file");

    reader.onloadend = () => {
      setImageUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleChatData = (val) => {
    setPerChat(val)
    if (!isCollapsed && matches) {
      // handleToggle()
    } else {
      setMovileView(true)
    }

  }

  const chatHistChat1 = (
    <div className='msglist'>
      <div className={(matches && isCollapsed) ? 'msg-lsit-head display-3' : 'msg-list-head display-2'}>
        <h3 className='chats cursor'>
          {/* [] */}
          {(matches && isCollapsed) ? <WidgetsIcon sx={{ fontSize: '25px !important', fill: '#7a7a7a' }} onClick={handleToggle} /> : 'Chats'}
        </h3>
        {(!isCollapsed && matches) ? <div div className='msg-lsit-head-svg cursor'>
          <AddCircleIcon onClick={handleOpen} />

        </div> : <></>}
      </div>

      {
        !isCollapsed && <form class="form">
          <label for="search">
            <input class="inputs" type="text" required="" placeholder="Search twitter" id="search" />
            <div class="fancy-bg"></div>
            <div class="search">
              <svg viewBox="0 0 24 24" aria-hidden="true" class="r-14j79pv r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-4wgw6l r-f727ji r-bnwqim r-1plcrui r-lrvibr">
                <g>
                  <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                </g>
              </svg>
            </div>

          </label>
        </form>
      }



      <div className='msg-list-area'>
        {chat.filter((obj) => {
          return (
            search?.toLocaleLowerCase() === undefined ? obj : obj.name.toLocaleLowerCase().includes(search)
          )

        }).map((row, ind) => {
          return (

            <div className="display-2 mt-30 cursor" onClick={(() => { handleChatData(row) })}>
              <div key={ind} className='msg-list-chat-main display-1 ' >
                <Badge
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot"
                  className="online-bdg"
                >
                  <div>
                    <Avatar sx={{ width: 36, height: 36 }} alt="Remy Sharp" src={row.img} />
                  </div>
                </Badge>
                <div className='msg-list-chat-name' >
                  {row.name}
                  <div className='chat-desc display-4' id='sentence' style={{ width: '100%' }}>
                    {(row.noftify?.length > 30) ? row.noftify.substring(0, 28) + '...' : row.noftify}

                  </div>
                </div>
              </div>
              <div>

                <Badge badgeContent={4} color="primary" className="bdg">
                  {/* <MailIcon color="action" /> */}
                </Badge>
              </div>
            </div>
          )
        })}

      </div>
    </div >
  )



  const pvtChat1 = (
    <div className="msglist">
      <div className="colab-avatar-cont vr">
        <Stack direction="row" spacing={-0.5}>
          {<div div className="cursor">
            {(!matches) ? <ArrowBackIosIcon sx={{ fontSize: '25px !important', fill: '#7a7a7a' }}
              onClick={(() => { movileView && setMovileView(false) })} /> : <></>}
          </div>}
          {/* <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist1} />
                    <Avatar sx={{ width: 30, height: 30 }} alt="Travis Howard" src={artist2} />
                    <Avatar sx={{ width: 30, height: 30 }} alt="Cindy Baker" src={artist3} /> */}
        </Stack>
      </div>
      <div className="colab-chat-area msg">


        <div className="chat-area-inner-text vr">
          <div>
            <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={perChat.img} />
          </div>
          <div className="collab-send-text-main">
            <div className="username">{perChat.name}(dev)</div>
            <div className="txt1">{perChat.noftify}</div>
            <div className="time">11:45am</div>
          </div>
        </div>
        {message.map((todo, ind) => {
          return (

            <div className="chat-area-inner-text user vr" key={ind}>
              <div>
                <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={perChat.img} />
              </div>
              <div className="collab-send-text-main2 user">
                <div className="username">Me(dev)</div>
                <div className="txt1">{todo}</div>
                <div className="time">11:45am</div>
              </div>
            </div>
          )
        })}
      </div>



      <div class="messageBox">
        <div class="fileUploadWrapper">
          <label for="file">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 337 337">
              <circle
                strokeWidth="20"
                stroke="#6c6c6c"
                fill="none"
                r="158.5"
                cy="168.5"
                cx="168.5"
              ></circle>
              <path
                strokeLinecap="round"
                strokeWidth="25"
                stroke="#6c6c6c"
                d="M167.759 79V259"
              ></path>
              <path
                strokeLinecap="round"
                strokeWidth="25"
                stroke="#6c6c6c"
                d="M79 167.138H259"
              ></path>
            </svg>
            <span class="tooltip">Add an image</span>
          </label>
          <input type="file" id="file" name="file" />
        </div>
        <input required="" placeholder="Message..." type="text" id="messageInput"
          value={updated}
          onChange={handleChangeSend}
          onKeyDown={handleEnterKeyPress}
        />
        <button id="sendButton" onClick={handleClickSend}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 664 663">
            <path
              fill="none"
              d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
            ></path>
            <path
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="33.67"
              stroke="#6c6c6c"
              d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
            ></path>
          </svg>
        </button>
      </div>

    </div>
  )


  return (
    <div className="support-full">
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
          <Header />
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
          <Grid container spacing={2} sx={{ justifyContent: 'center', marginTop: '10px' }} >
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} className=" ">
              {(!matches && movileView) ? pvtChat1 : chatHistChat1}
            </Grid>
            {matches && <Grid item xs={12} sm={12} md={6} lg={8} xl={9} className=" ">
              {pvtChat1}
            </Grid>}


          </Grid>
        </Grid>

      </Grid >



      {/* ticket modal */}
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="close-icon new cursor">
            <HighlightOffIcon sx={{ fontSize: '30px' }} onClick={handleClose} />
          </div>
          <div className="create-ticket">
            <h2 style={{ color: 'var(--clr-font)' }}>Create Ticket</h2>
          </div>
          <div className="create-field">
            <div className="email-fill mok">
              <div className="email-name mod">
                Title
              </div>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                // label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
            </div>
            <div className="email-fill mok mt-10">
              <div className="email-name mod">
                Reason
              </div>
              <TextField
                id="outlined-multiline-static"
                margin="normal"
                fullWidth
                multiline
                rows={4}
              // defaultValue="Default Value"
              />
            </div>
            <div className="submit mt-20">
              <Button variant="contained">Submit</Button>
            </div>
          </div>
        </Box>
      </Modal>
      {/* ticket modal */}
    </div >
  );
};

export default Support;
