import { Grid } from "@mui/material";
import React, { useState, useRef, useEffect } from 'react'
import Header from "../Header/Header";
import Footer from "../Footer/footer";

import "./home.scss";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import Btc from '../../Img/btc.png'
import eth from '../../Img/eth.png'
import tht from '../../Img/theter.png'
import shiba from '../../Img/shiba.svg'
import mana from '../../Img/mana.svg'

import trending_icon from '../../Img/trending_icon.svg'
import right_arw from '../../Img/right_arw.svg'
import zec from '../../Img/coins/zec.png'
import xec from '../../Img/coins/xec.png'
import kcs from '../../Img/coins/kcs.png'
import scr from '../../Img/coins/scr.png'
import cat from '../../Img/coins/cat.png'
import woop from '../../Img/coins/woop.png'
import pip from '../../Img/coins/pip.png'
import insp from '../../Img/coins/xec.png'
import ese from '../../Img/coins/ese.png'
import sol from '../../Img/coins/sol.png'
import down from '../../Img/down.svg'
import up from '../../Img/up.svg'
import top_gainers from '../../Img/top_gainers.png'
import top_losers from '../../Img/top_losers.png'
import search from '../../Img/search.svg'
import star from '../../Img/star.svg'

import banimg from '../../Img/banimg.png'
import banimg_white from '../../Img/banimg_white.png'
import blw1 from '../../Img/blw1.png'
import blw2 from '../../Img/blw2.png'
import chart_table from '../../Img/chart_table.png'
import home_sec1 from '../../Img/home_sec1.webp'
import home_sec2 from '../../Img/home_sec2.webp'
import home_sec3 from '../../Img/home_sec3.webp'
import y1 from '../../Img/y1.webp'
import y2 from '../../Img/y2.webp'
import y3 from '../../Img/y3.webp'
import y4 from '../../Img/y4.webp'
import b1 from '../../Img/b1.webp'
import b2 from '../../Img/b2.webp'
import b3 from '../../Img/b3.webp'
import right_arw_orange from '../../Img/right_arw_orange.png';
import c1 from '../../Img/c1.webp';
import c2 from '../../Img/c2.webp';
import c3 from '../../Img/c3.webp';
import c4 from '../../Img/c4.webp';
import qrcode from '../../Img/qrcode.png';
import t1 from '../../Img/t1.png';
import t2 from '../../Img/t2.png';
import t3 from '../../Img/t3.png';
import t4 from '../../Img/t4.png';
import trade_img from '../../Img/trade_img.webp';
import h1 from '../../Img/h1.png';
import h2 from '../../Img/h2.png';
import h3 from '../../Img/h3.png';
import h4 from '../../Img/h4.png';
import h5 from '../../Img/h5.png';
import h6 from '../../Img/h6.png';
import h7 from '../../Img/h7.png';
import h8 from '../../Img/h8.png';
import h9 from '../../Img/h9.png';
import h10 from '../../Img/h10.png';
import prf from '../../Img/prf.png';
import card_chart from '../../Img/card_chart.png';
import card_icon from '../../Img/card_icon.png';
import consts from "../../constant";
import usercalls from "../../Axios";
import $ from 'jquery'

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Home = () => {
    const [value, setValue] = React.useState(0);
    const [topGainers, setTopGainers] = useState([]);
    const [topLoosers, setTopLoosers] = useState([]);
    const [topVolume, setTopVolume] = useState([]);
    const [allPairs, setAllPairs] = useState([]);


    const path = usercalls();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [market, setmarket] = React.useState(0);

    const handleChangemarket = (event, newmarket) => {
        setmarket(newmarket);
    };

    const [all, setall] = React.useState(0);

    const handleChangeall = (event, e) => {
        setall(e);
    };

    const [fav, setfav] = React.useState(0);

    const handleChangefav = (event, e) => {
        setfav(e);
    };

    const cards = [
        {
            img: `${Btc}`,
            coinname: "BTC",
            coinrate: "+7.09%",
            coinvalue: "$ 17.228000",
            coinname: "USDT",
        },
        {
            img: `${eth}`,
            coinname: "ETH",
            coinrate: "+7.09%",
            coinvalue: "$ 17.228000",
            coinname: "USDT",
        },
        {
            img: `${mana}`,
            coinname: "MANA",
            coinrate: "+7.09%",
            coinvalue: "$ 17.228000",
            coinname: "USDT",
        },
        {
            img: `${shiba}`,
            coinname: "SHIB",
            coinrate: "+7.09%",
            coinvalue: "$ 17.228000",
            coinname: "USDT",
        },
    ];

    const cardElements = cards.map((card) => (
        <div className="token-card">

            <div className="token-logo display-2">
                <div className="token-name">
                    <div className="token-logo">
                        <img src={card.img} alt="coin-name" />
                    </div>
                    {card.coinname}
                </div>
                <div className="token-rate green-bg">
                    <div class="check_box"><div class="chk"><input type="checkbox" /><span class="round"></span></div></div>
                </div>
            </div>
            <div className="token-logo display-2">
                <div className="token-rate">
                    <h5> {card.coinvalue}</h5>
                    <p className="green" >{card.coinrate}</p>
                </div>
                <div className="token-name">{card.coinname}</div>
            </div>
        </div>
    ));

    const tableHistory = [
        {
            img: `${Btc}`,
            coin: "BTCUSDT",
            price: "$66,966.49",
            price1: "￥481212.56",
            change: "-2.06%",
            volume: "5,037,427,607 USDT",
            chart: `${chart_table}`,
            actions: "Trade",
        },
        {
            img: `${eth}`,
            coin: "ETHUSDT",
            price: "$2,626.25",
            price1: "￥18895.22",
            change: "-3.06%",
            volume: "10,867,107,187 USDT",
            chart: `${chart_table}`,
            actions: "Trade",
        },
        {
            img: `${sol}`,
            coin: "SOLUSDT",
            price: "$164.94",
            price1: "￥18895.22",
            change: "-1.06%",
            volume: "102,163,422 USDT",
            chart: `${chart_table}`,
            actions: "Trade",
        },
        {
            img: `${Btc}`,
            coin: "BTCUSDT",
            price: "$66,966.49",
            price1: "￥481212.56",
            change: "-2.06%",
            volume: "5,037,427,607 USDT",
            chart: `${chart_table}`,
            actions: "Trade",
        },
        {
            img: `${eth}`,
            coin: "ETHUSDT",
            price: "$2,626.25",
            price1: "￥18895.22",
            change: "-3.06%",
            volume: "10,867,107,187 USDT",
            chart: `${chart_table}`,
            actions: "Trade",
        },
        {
            img: `${sol}`,
            coin: "SOLUSDT",
            price: "$164.94",
            price1: "￥18895.22",
            change: "-1.06%",
            volume: "102,163,422 USDT",
            chart: `${chart_table}`,
            actions: "Trade",
        },
    ];

    const tableElements = tableHistory.map((table) => (
        <TableRow className="cursor">
            <TableCell align="left">
                <div className="display-1 coin-gap">
                    {/* <div className="star-img">
                        <img src={star} alt="star" />
                    </div> */}
                    <div className="coin-img">
                        <img src={table.img} alt="coin" />
                    </div>
                    <div className="coin-name">
                        <p> {table.coin}</p>
                        {/* <span> {table.coinname}</span> */}
                    </div>
                </div>


            </TableCell>
            <TableCell align="left">{table.price}<div className="grey" >{table.price1}</div></TableCell>
            <TableCell align="left" className="red" >{table.change}</TableCell>
            <TableCell align="left">{table.volume}</TableCell>
            <TableCell align="left"> <img src={table.chart} alt="star" /></TableCell>
            <TableCell align="right">
                <div className="actions" >
                    <Link to={`/trade/${consts?.MainPair}`}>{table.actions}</Link>
                </div>
            </TableCell>
        </TableRow>
    ));

    const topGain = topGainers.map((table) => (
        <TableRow className="cursor">
            <TableCell align="center">
                <div className="display-1 coin-gap">
                    {/* <div className="star-img">
                        <img src={star} alt="star" />
                    </div> */}
                    {/* <div className="coin-img">
                        <img src={table?.img ? table?.img : Btc} alt="coin" />
                    </div> */}
                    <div className="coin-name">
                        <p> {table?.symbol}</p>
                        {/* <span> {table.coinname}</span> */}
                    </div>
                </div>


            </TableCell>
            <TableCell align="center"><div className={`prices-${table?.symbol} grey`} >{table?.lastPrice}</div></TableCell>
            <TableCell align="center" className={`24hpc-${table?.symbol} red`} >{table?.priceChangePercent}</TableCell>
            <TableCell align="center" className={`vol-${table?.symbol}`}>{table?.volume}</TableCell>
            <TableCell align="center"> <img src={chart_table} alt="star" /></TableCell>
            {/* <TableCell align="center">
                <div className="actions" >
                    <Link to={`/trade/${table?.symbol}`}>Trade</Link>
                </div>
            </TableCell> */}
        </TableRow>
    ));

    const topLoss = topLoosers.map((table) => (
        <TableRow className="cursor">
            <TableCell align="left">
                <div className="display-1 coin-gap">
                    {/* <div className="star-img">
                        <img src={star} alt="star" />
                    </div> */}
                    {/* <div className="coin-img">
                        <img src={table?.img ? table?.img : Btc} alt="coin" />
                    </div> */}
                    <div className="coin-name">
                        <p> {table?.symbol}</p>
                        {/* <span> {table.coinname}</span> */}
                    </div>
                </div>


            </TableCell>
            <TableCell align="center"><div className={`prices-${table?.symbol} grey`} >{table?.lastPrice}</div></TableCell>
            <TableCell align="center" className={`24hpc-${table?.symbol} red`} >{table?.priceChangePercent}</TableCell>
            <TableCell align="center" className={`vol-${table?.symbol}`}>{table?.volume}</TableCell>
            <TableCell align="center"> <img src={chart_table} alt="star" /></TableCell>
            {/* <TableCell align="center">
                <div className="actions" >
                    <Link to={`/trade/${table?.baseCoin}`}>Trade</Link>
                </div>
            </TableCell> */}
        </TableRow>
    ));

    const topVol = topVolume.map((table) => (
        <TableRow className="cursor">
            <TableCell align="left">
                <div className="display-1 coin-gap">
                    {/* <div className="star-img">
                        <img src={star} alt="star" />
                    </div> */}
                    {/* <div className="coin-img">
                        <img src={table?.img ? table?.img : Btc} alt="coin" />
                    </div> */}
                    <div className="coin-name">
                        <p> {table?.symbol}</p>
                        {/* <span> {table.coinname}</span> */}
                    </div>
                </div>


            </TableCell>
            <TableCell align="center"><div className={`prices-${table?.symbol} grey`} >{table?.lastPrice}</div></TableCell>
            <TableCell align="center" className={`24hpc-${table?.symbol} red`} >{table?.priceChangePercent}</TableCell>
            <TableCell align="center" className={`vol-${table?.symbol}`}>{table?.volume}</TableCell>
            <TableCell align="center"> <img src={chart_table} alt="star" /></TableCell>
            {/* <TableCell align="center">
                <div className="actions" >
                    <Link to={`/trade/${table?.symbol}`}>Trade</Link>
                </div>
            </TableCell> */}
        </TableRow>
    ));


    const [hoverIndex, setHoverIndex] = useState(null);

    const data = [
        {
            title: "Compliant Operations",
            description:
                "We are equipped with an industry-leading security team, and our SSL Labs have achieved A+ ratings on all 12 indicators.",
            img: c1,
        },
        {
            title: "Smooth Experience",
            description:
                "We are equipped with an industry-leading security team, and our SSL Labs have achieved A+ ratings on all 12 indicators.",
            img: c2,
        },
        {
            title: "Protection Fund",
            description:
                "We are equipped with an industry-leading security team, and our SSL Labs have achieved A+ ratings on all 12 indicators.",
            img: c3,
        },
        {
            title: "Safe and Reliable",
            description:
                "We are equipped with an industry-leading security team, and our SSL Labs have achieved A+ ratings on all 12 indicators.",
            img: c4,
        },
    ];

    const [status, setStatus] = useState(window.localStorage.getItem("Rikosta"))


    const getTopGainers = async () => {

        try {

            const url = `${consts.BackendUrl}/topgainers`
            const response = await path.getCall({ url });
            const result = await response.json();


            if (result?.success) {

                // Extract symbols from top gainers, losers, and volume pairs and Formatting For webSocket
                const topGainersSymbols = result?.topgainers?.map(item => `${item?.symbol?.toLowerCase()}@ticker`) || [];
                const topLosersSymbols = result?.toplosers?.map(item => `${item?.symbol?.toLowerCase()}@ticker`) || [];
                const topVolumeSymbols = result?.topvolume?.map(item => `${item?.symbol?.toLowerCase()}@ticker`) || [];


                setTopGainers(result?.topgainers);
                setTopLoosers(result?.toplosers);
                setTopVolume(result?.topvolume);
                setAllPairs([...topGainersSymbols, ...topLosersSymbols, ...topVolumeSymbols]);

            }

        } catch (error) {
            console.log(error, "error getting topgainers");
        }

    };

    useEffect(() => {
        getTopGainers();
    }, [])

    const currentSocket = async () => {
        try {
            // console.log(allPairs, "allPairsallPairs");
            const ws = new WebSocket("wss://stream.binance.com:9443/ws");
            // setCurrentSoc(ws)
            ws.onopen = (event) => {


                const priceApicall = {
                    "method": "SUBSCRIBE",
                    "params": allPairs,
                    "id": 1
                }
                ws.send(JSON.stringify(priceApicall));
            };

            ws.onmessage = function (event) {
                const json = JSON.parse(event.data);
                // console.log(json, "jsonevent");
                try {
                    $(`.prices-${json?.s}`).html(`<span class=${Number(json?.P).toFixed(2) > 0 ? 'tr-green' : 'tr-red'} >${Number(json?.c).toFixed(4)}</span>`);
                    $(`.24hp-${json?.s}`).html(`<span class=${Number(json?.P).toFixed(2) > 0 ? 'tr-green' : 'tr-red'} >${Number(json?.p).toFixed(2)}</span>`);
                    $(`.24hpc-${json?.s}`).html(`<span class=${Number(json?.P).toFixed(2) > 0 ? 'tr-green' : 'tr-red'} >${Number(json?.P).toFixed(2) + "%"}</span>`);
                    $(`.open-${json?.s}`).html(Number(json?.o).toFixed(2));
                    $(`.vol-${json?.s}`).html(Number(json?.v).toFixed(2));
                    $(`.high-${json?.s}`).html(Number(json?.h).toFixed(2));
                    $(`.low-${json?.s}`).html(Number(json?.l).toFixed(2));
                } catch (error) {
                    console.log(error, 'err');

                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket Error:', error);
            };

            ws.onclose = () => {
                console.log('WebSocket Connection Closed');
            };

            return () => {
                console.log('Cleaning up WebSocket connection');
                ws.close();
            };
        } catch (error) {
            console.log("🚀 ~ currentSocket ~ error:", error)
        }
    }

    useEffect(() => {

        if (allPairs.length > 0) {
            currentSocket();
        }

    }, [allPairs]);



    return (
        <div className="market-full">
            <Header />
            <section className="banner" >
                <div className="contain-width">
                    <Grid container>
                        <Grid xs={12} sm={12} md={12} lg={6} xl={7} sx={{ margin: "auto" }}>
                            <h1>Gateway to Digital Asset,Highway to Wealth</h1>
                            <p>GROUNDBREAKING TOOLS TO INVEST IN CRYPTO</p>
                            {
                                status ?
                                    <></>
                                    :
                                    <div class="input_grp">
                                        <div class="input_txt right cursor-pointer">
                                            <input type="text" placeholder="Email" onChange={(e) => { window.localStorage.setItem("email", e.target.value) }} />
                                            <Link to={"/register"}>Let's Roll</Link>
                                        </div>
                                    </div>
                            }
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={6} xl={5}>
                            <div className="img_b">
                                <img src={banimg} alt="img" className="dark" />
                                <img src={banimg_white} alt="img" className="light" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="ban_blw">
                <div className="contain-width">
                    <Grid container gap={3} className="slide" >
                        <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                            <div className="box">
                                <div>
                                    <span>Account Security Alert</span>
                                    <h5>Security Enhancement: <br />New Password Rules</h5>
                                    <Link to={"#"}>Update Your Passwords Now &nbsp;
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.47815 1.38672L4.9443 4.85287L1.47815 8.31903" stroke="#FE7701" strokeWidth="1.38462" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    </Link>
                                </div>
                                <div>
                                    <img src={blw1} alt="img" />
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                            <div className="box">
                                <div>
                                    <span>Security Notice</span>
                                    <h5>Only official website<br />www.deepcoin.com</h5>
                                    <Link to={"#"}>Be alert of any potential scams &nbsp;
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.47815 1.38672L4.9443 4.85287L1.47815 8.31903" stroke="#FE7701" strokeWidth="1.38462" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    </Link>
                                </div>
                                <div>
                                    <img src={blw2} alt="img" />
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                            <div className="box">
                                <div>
                                    <span>Account Security Alert</span>
                                    <h5>Security Enhancement: <br />New Password Rules</h5>
                                    <Link to={"#"}>Update Your Passwords Now &nbsp;
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.47815 1.38672L4.9443 4.85287L1.47815 8.31903" stroke="#FE7701" strokeWidth="1.38462" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    </Link>
                                </div>
                                <div>
                                    <img src={blw1} alt="img" />
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                            <div className="box">
                                <div>
                                    <span>Security Notice</span>
                                    <h5>Only official website<br />www.deepcoin.com</h5>
                                    <Link to={"#"}>Be alert of any potential scams &nbsp;
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.47815 1.38672L4.9443 4.85287L1.47815 8.31903" stroke="#FE7701" strokeWidth="1.38462" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    </Link>
                                </div>
                                <div>
                                    <img src={blw2} alt="img" />
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                            <div className="box">
                                <div>
                                    <span>Account Security Alert</span>
                                    <h5>Security Enhancement: <br />New Password Rules</h5>
                                    <Link to={"#"}>Update Your Passwords Now &nbsp;
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.47815 1.38672L4.9443 4.85287L1.47815 8.31903" stroke="#FE7701" strokeWidth="1.38462" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    </Link>
                                </div>
                                <div>
                                    <img src={blw1} alt="img" />
                                </div>
                            </div>
                        </Grid>
                        {/* <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                             <div className="box">
                                 <div>
                                    <span>Security Notice</span>
                                    <h5>Only official website<br />www.deepcoin.com</h5>
                                    <Link to={"#"}>Be alert of any potential scams &nbsp;
                                       <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.47815 1.38672L4.9443 4.85287L1.47815 8.31903" stroke="#FE7701" strokeWidth="1.38462" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    </Link>
                                 </div>
                                 <div>
                                    <img src={blw2} alt="img"   />
                                 </div>
                             </div>
                        </Grid>  */}
                    </Grid>
                    <Grid container gap={3} className="slide" >
                        <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                            <div className="box">
                                <div>
                                    <span>Account Security Alert</span>
                                    <h5>Security Enhancement: <br />New Password Rules</h5>
                                    <Link to={"#"}>Update Your Passwords Now &nbsp;
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.47815 1.38672L4.9443 4.85287L1.47815 8.31903" stroke="#FE7701" strokeWidth="1.38462" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    </Link>
                                </div>
                                <div>
                                    <img src={blw1} alt="img" />
                                </div>
                            </div>
                        </Grid>
                        {/* <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                             <div className="box">
                                 <div>
                                    <span>Security Notice</span>
                                    <h5>Only official website<br />www.deepcoin.com</h5>
                                    <Link to={"#"}>Be alert of any potential scams &nbsp;
                                       <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.47815 1.38672L4.9443 4.85287L1.47815 8.31903" stroke="#FE7701" strokeWidth="1.38462" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    </Link>
                                 </div>
                                 <div>
                                    <img src={blw2} alt="img"   />
                                 </div>
                             </div>
                        </Grid> */}
                        <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                            <div className="box">
                                <div>
                                    <span>Account Security Alert</span>
                                    <h5>Security Enhancement: <br />New Password Rules</h5>
                                    <Link to={"#"}>Update Your Passwords Now &nbsp;
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.47815 1.38672L4.9443 4.85287L1.47815 8.31903" stroke="#FE7701" strokeWidth="1.38462" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    </Link>
                                </div>
                                <div>
                                    <img src={blw1} alt="img" />
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                            <div className="box">
                                <div>
                                    <span>Security Notice</span>
                                    <h5>Only official website<br />www.deepcoin.com</h5>
                                    <Link to={"#"}>Be alert of any potential scams &nbsp;
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.47815 1.38672L4.9443 4.85287L1.47815 8.31903" stroke="#FE7701" strokeWidth="1.38462" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    </Link>
                                </div>
                                <div>
                                    <img src={blw2} alt="img" />
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                            <div className="box">
                                <div>
                                    <span>Account Security Alert</span>
                                    <h5>Security Enhancement: <br />New Password Rules</h5>
                                    <Link to={"#"}>Update Your Passwords Now &nbsp;
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.47815 1.38672L4.9443 4.85287L1.47815 8.31903" stroke="#FE7701" strokeWidth="1.38462" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    </Link>
                                </div>
                                <div>
                                    <img src={blw1} alt="img" />
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={3} xl={3}>
                            <div className="box">
                                <div>
                                    <span>Security Notice</span>
                                    <h5>Only official website<br />www.deepcoin.com</h5>
                                    <Link to={"#"}>Be alert of any potential scams &nbsp;
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.47815 1.38672L4.9443 4.85287L1.47815 8.31903" stroke="#FE7701" strokeWidth="1.38462" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    </Link>
                                </div>
                                <div>
                                    <img src={blw2} alt="img" />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="home_market">
                <div className="contain-width">
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ margin: "auto" }} >
                            <div className="ticket-box" style={{ paddingTop: "1px" }}>
                                <div className="market-current-history">
                                    <h2 className="h_2" >Market</h2>
                                    <div className="display-2 tab_bdr" >
                                        <Tabs
                                            value={market}
                                            onChange={handleChangemarket}
                                            aria-label="basic tabs example"
                                        >
                                            <Tab label="Lossers" {...a11yProps(0)} />
                                            <Tab label="Gainers" {...a11yProps(1)} />
                                            <Tab label="Volume" {...a11yProps(2)} />
                                        </Tabs>
                                    </div>
                                    <CustomTabPanel value={market} index={0} class="tab-content">
                                        <div className="market-table">
                                            <TableContainer>
                                                <Table aria-label="simple table" stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">Pair</TableCell>
                                                            <TableCell align="center">Price</TableCell>
                                                            <TableCell align="center">24h Change</TableCell>
                                                            <TableCell align="center">24h volume</TableCell>
                                                            <TableCell align="center">24h chart</TableCell>
                                                            {/* <TableCell align="center">Action</TableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {topLoss}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={market} index={1} class="tab-content">
                                        <div className="market-table">
                                            <TableContainer>
                                                <Table aria-label="simple table" stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">Pair</TableCell>
                                                            <TableCell align="center">Price</TableCell>
                                                            <TableCell align="center">24h Change</TableCell>
                                                            <TableCell align="center">Volume</TableCell>
                                                            <TableCell align="center">24h chart</TableCell>
                                                            {/* <TableCell align="right">24h Volume</TableCell> */}
                                                            {/* <TableCell align="center">Action</TableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {topGain}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={market} index={2} class="tab-content">
                                        <div className="market-table">
                                            <TableContainer>
                                                <Table aria-label="simple table" stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">Pair</TableCell>
                                                            <TableCell align="center">Price</TableCell>
                                                            <TableCell align="center">24h Change</TableCell>
                                                            <TableCell align="center">Volume</TableCell>
                                                            <TableCell align="center">24h chart</TableCell>
                                                            {/* <TableCell align="center">24h Volume</TableCell> */}
                                                            {/* <TableCell align="center">Action</TableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {topVol}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </CustomTabPanel>
                                </div>

                            </div>
                        </Grid>
                    </Grid>
                    <Link to={"/market"} className="view_more" >View More</Link>
                </div>
            </section>
            <section className="home_secs">
                <div className="contain-width">
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ margin: "auto" }} >
                            <div className="img_box">
                                <img src={home_sec1} alt="img" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ margin: "auto" }} >
                            <h2 className="h_2" >Top-class experience in digital assets investment</h2>
                            <p>With low barriers to entry, we will offer you all-in-one investment services in derivatives
                                and finance based on spot trading.</p>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="home_secs home_secs_chg">
                <div className="contain-width">
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ margin: "auto" }} >
                            <h2 className="h_2" >Diversified trading options</h2>
                            <p>A wide range of trading products can help you explore and play with cryptocurrencies, including spot, leverage, futures, ETF, index, and other trading varieties.</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ margin: "auto" }} >
                            <div className="img_box">
                                <img src={home_sec2} alt="img" />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="home_secs">
                <div className="contain-width">
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ margin: "auto" }} >
                            <div className="img_box">
                                <img src={home_sec3} alt="img" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ margin: "auto" }} >
                            <h2 className="h_2" >Top security risk control system</h2>
                            <p>Industry-leading security technology and 24/7 platform monitoring ensure that your
                                assets are protected in all aspects.</p>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="your_trusted">
                <div className="contain-width">
                    <h2 className="h_2" >Your Trusted Crypto Exchange</h2>
                    <p className="s_h" >Buy, trade, and hold 150+ cryptocurrencies on Deepcoin</p>
                    <Grid container spacing={5} >
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{ display: "flex" }} >
                            <div className="box">
                                <div className="img_box">
                                    <img src={y1} alt="img" />
                                </div>
                                <h5>Superb R&D</h5>
                                <p>Self-developed third-gen trading system from scratch.Exclusive features to give you the ultimate trading experience without fail.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{ display: "flex" }} >
                            <div className="box">
                                <div className="img_box">
                                    <img src={y2} alt="img" />
                                </div>
                                <h5>Global Innovative Team</h5>
                                <p>Blockchain & tech specialists, culturally diverse team deeply involved in the digital currency field for many years.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{ display: "flex" }} >
                            <div className="box">
                                <div className="img_box">
                                    <img src={y3} alt="img" />
                                </div>
                                <h5>Super Trade</h5>
                                <p>Exclusively launched a new Spot Assets function, allowing you more visibility into your direct crypto purchases and the P&L generated from sales.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{ display: "flex" }} >
                            <div className="box">
                                <div className="img_box">
                                    <img src={y4} alt="img" />
                                </div>
                                <h5>24/7 Multi Lingual Support</h5>
                                <p>One minute response time. Dedicated to getting you solutions</p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="build">
                <div className="contain-width">
                    <h2 className="h_2" >Build Your Portfolio</h2>
                    <Grid container spacing={2} sx={{ justifyContent: "center" }} className="whole_box" >
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ display: "flex" }} >
                            <div className="box">
                                <h5>Spot</h5>
                                <p>Hundreds of cryptos to choose <br />from. Easy to Trade.</p>
                                <Link to={`/trade/${consts?.MainPair}`}>Trade   <img src={right_arw_orange} alt="img" /></Link>
                                <div className="img_box">
                                    <img src={b1} alt="img" />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ display: "flex" }} >
                            <div className="box">
                                <h5>Future</h5>
                                <p>Up to 200x leverage with top-level <br />liquidity.</p>
                                <Link to={`/future/${consts?.MainPair}`}>Trade   <img src={right_arw_orange} alt="img" /></Link>
                                <div className="img_box">
                                    <img src={b2} alt="img" />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ display: "flex" }} >
                            <div className="box">
                                <h5>Copy Trading</h5>
                                <p>Follow professional traders to earn <br />with ease.</p>
                                <Link to={`/copytrade`}>Trade   <img src={right_arw_orange} alt="img" /></Link>
                                <div className="img_box portfolio">
                                    <img src={b3} alt="img" />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="why_choose">
                <div className="contain-width">
                    <Grid container spacing={2} sx={{ justifyContent: "center" }} >
                        <Grid item xs={12} sm={12} md={12} lg={10} xl={10} sx={{ margin: "auto" }} >
                            <h2 className="h_2" >Why choose KAAVI?</h2>
                            {/* <div className="flx">
                                <div>
                                    <h6>Compliant Operations</h6>
                                    <p>We are equipped with an industry-leading security team, and our SSL Labs have achieved A+ ratings on all 12 indicators.</p>
                                    <img src={c1} alt="img" />
                                </div>
                                <div>
                                    <h6>Smooth Experience</h6>
                                    <p>We are equipped with an industry-leading security team, and our SSL Labs have achieved A+ ratings on all 12 indicators.</p>
                                    <img src={c2} alt="img" />
                                </div>
                                <div>
                                    <h6>Protection Fund</h6>
                                    <p>We are equipped with an industry-leading security team, and our SSL Labs have achieved A+ ratings on all 12 indicators.</p>
                                    <img src={c3} alt="img" />
                                </div>
                                <div>
                                    <h6>Safe and Reliable</h6>
                                    <p>We are equipped with an industry-leading security team, and our SSL Labs have achieved A+ ratings on all 12 indicators.</p>
                                    <img src={c4} alt="img" />
                                </div>
                            </div> */}

                            <div className="flx">
                                {data.map((item, index) => (
                                    <div
                                        key={index}
                                        onMouseEnter={() => setHoverIndex(index)}
                                        onMouseLeave={() => setHoverIndex(null)}
                                        className={hoverIndex === index ? "hover" : ""}
                                    >
                                        <h6>{item.title}</h6>
                                        <p>{item.description}</p>
                                        <img src={item.img} alt="img" />
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="copy_trading">
                <div className="contain-width">
                    <Grid container spacing={2} sx={{ justifyContent: "center" }} >
                        <Grid item xs={12} sm={12} md={12} lg={10} xl={10} sx={{ margin: "auto" }} >

                            <div className="flx_box flx_box_chg">
                                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} sx={{ margin: "auto" }} >
                                    <div className="copy" >Copy Trading</div>
                                    <h2 className="h_2" >Never miss another market movement</h2>
                                    <span>Save time and effort by copying top traders</span>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ margin: "auto" }} >
                                    <Link to={"/copytrade"} className="start" >Start Copy Trading  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.89789 0.759766L19.0349 10.8968L16.8627 13.069L8.8976 21.0341L6.72534 18.8619L13.4391 12.1481H0.581589V9.07611H12.8697L6.72561 2.93201L8.89789 0.759766Z" fill="#FF7028" /></svg></Link>
                                </Grid>
                            </div>
                            <div className="flx_box">
                                <div>
                                    <h5>5,000+</h5>
                                    <div className="sub_n">Professional traders</div>
                                </div>
                                <div>
                                    <h5>50,000+</h5>
                                    <div className="sub_n">Followers</div>
                                </div>
                                <div>
                                    <h5>$800,000,000+</h5>
                                    <div className="sub_n">Followers</div>
                                </div>
                            </div>

                        </Grid>
                    </Grid>

                </div>
            </section>
            <section className="card_box">
                <div className="contain-width">
                    <Grid container spacing={2} sx={{ justifyContent: "center" }} >
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} >
                            <div className="box">
                                <div className="h_flx">
                                    <div className="flx" >
                                        <img src={prf} alt="img" />
                                        <div class="w-100">
                                            <h5>Xiao Hai</h5>
                                            <span>Too busy making money to …</span>
                                        </div>
                                    </div>
                                    <div>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                        <div>8 / <div className="grey">100</div></div>
                                    </div>
                                </div>
                                <div className="flx">
                                    <div>
                                        <div className="green">+6.64%</div>
                                        <span>ROI</span>
                                    </div>
                                    <div className="days">30 days</div>
                                </div>
                                <img src={card_chart} alt="img" />
                                <div className="f_box">
                                    <div>
                                        <div>Total PnL (3W)</div>
                                        <div>$356.54</div>
                                    </div>
                                    <div>
                                        <div>Copy trading PnL (3W)</div>
                                        <div>$29550.81</div>
                                    </div>
                                    <div>
                                        <div>Win rate (3W)</div>
                                        <div>65.80%</div>
                                    </div>
                                    <div>
                                        <div className="coins" >
                                            <img src={kcs} alt="img" />
                                            <img src={scr} alt="img" />
                                            <img src={cat} alt="img" />
                                        </div>
                                        <div>
                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                    </div>
                                </div>
                                <Link to={"#"} className="copy_btn" >Copy</Link>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} >
                            <div className="box">
                                <div className="h_flx">
                                    <div className="flx" >
                                        <img src={prf} alt="img" />
                                        <div class="w-100">
                                            <h5>Xiao Hai</h5>
                                            <span>Too busy making money to …</span>
                                        </div>
                                    </div>
                                    <div>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                        <div>8 / <div className="grey">100</div></div>
                                    </div>
                                </div>
                                <div className="flx">
                                    <div>
                                        <div className="green">+6.64%</div>
                                        <span>ROI</span>
                                    </div>
                                    <div className="days">30 days</div>
                                </div>
                                <img src={card_chart} alt="img" />
                                <div className="f_box">
                                    <div>
                                        <div>Total PnL (3W)</div>
                                        <div>$356.54</div>
                                    </div>
                                    <div>
                                        <div>Copy trading PnL (3W)</div>
                                        <div>$29550.81</div>
                                    </div>
                                    <div>
                                        <div>Win rate (3W)</div>
                                        <div>65.80%</div>
                                    </div>
                                    <div>
                                        <div className="coins" >
                                            <img src={kcs} alt="img" />
                                            <img src={scr} alt="img" />
                                            <img src={cat} alt="img" />
                                        </div>
                                        <div>
                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                    </div>
                                </div>
                                <Link to={"/copytrade"} className="copy_btn" >Copy</Link>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={3} >
                            <div className="box">
                                <div className="h_flx">
                                    <div className="flx" >
                                        <img src={prf} alt="img" />
                                        <div class="w-100">
                                            <h5>Xiao Hai</h5>
                                            <span>Too busy making money to …</span>
                                        </div>
                                    </div>
                                    <div>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                        <div>8 / <div className="grey">100</div></div>
                                    </div>
                                </div>
                                <div className="flx">
                                    <div>
                                        <div className="green">+6.64%</div>
                                        <span>ROI</span>
                                    </div>
                                    <div className="days">30 days</div>
                                </div>
                                <img src={card_chart} alt="img" />
                                <div className="f_box">
                                    <div>
                                        <div>Total PnL (3W)</div>
                                        <div>$356.54</div>
                                    </div>
                                    <div>
                                        <div>Copy trading PnL (3W)</div>
                                        <div>$29550.81</div>
                                    </div>
                                    <div>
                                        <div>Win rate (3W)</div>
                                        <div>65.80%</div>
                                    </div>
                                    <div>
                                        <div className="coins" >
                                            <img src={kcs} alt="img" />
                                            <img src={scr} alt="img" />
                                            <img src={cat} alt="img" />
                                        </div>
                                        <div>
                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                    </div>
                                </div>
                                <Link to={"#"} className="copy_btn" >Copy</Link>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="trade_any">
                <div className="contain-width">
                    <Grid container spacing={2}   >
                        <Grid item xs={12} sm={12} md={12} lg={10} xl={10} sx={{ margin: "auto" }} >
                            <Grid container spacing={2}   >
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={7} sx={{ margin: "auto" }} >
                                    <h2 className="h_2" >Trade. Anywhere.</h2>
                                    <p>Scan the code to download on Android or iOS</p>
                                    <div className="qr_b">
                                        <img src={qrcode} alt="img" />
                                        <div>
                                            <div className="scan">Scan to download on</div>
                                            <h5>iOS & Android</h5>
                                        </div>
                                    </div>
                                    <div className="r_f">
                                        <div>
                                            <img src={t1} alt="img" />
                                            <span>TestFlight</span>
                                        </div>
                                        <div>
                                            <img src={t2} alt="img" />
                                            <span>App Store</span>
                                        </div>
                                        <div>
                                            <img src={t3} alt="img" />
                                            <span>Android APK</span>
                                        </div>
                                        <div>
                                            <img src={t4} alt="img" />
                                            <span>Google Play</span>
                                        </div>
                                    </div>

                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={5} sx={{ margin: "auto", textAlign: "center" }} >

                                    <img src={trade_img} alt="img" />

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </section>
            <section className="ban_blw ban_blw_chg">
                <div className="contain-width">
                    <div className="slide">
                        <img src={h1} alt="img" />
                        <img src={h2} alt="img" />
                        <img src={h3} alt="img" />
                        <img src={h4} alt="img" />
                        <img src={h5} alt="img" />
                        <img src={h6} alt="img" />
                        <img src={h7} alt="img" />
                        <img src={h8} alt="img" />
                        <img src={h9} alt="img" />
                        <img src={h10} alt="img" />
                    </div>
                    <div className="slide">
                        <img src={h1} alt="img" />
                        <img src={h2} alt="img" />
                        <img src={h3} alt="img" />
                        <img src={h4} alt="img" />
                        <img src={h5} alt="img" />
                        <img src={h6} alt="img" />
                        <img src={h7} alt="img" />
                        <img src={h8} alt="img" />
                        <img src={h9} alt="img" />
                        <img src={h10} alt="img" />
                    </div>
                </div>
            </section>
            <section className="trade_now">
                <div className="contain-width">
                    {!status && <div>
                        <h2 className="h_2" >Trade Now</h2>
                        <p>Sign up in 10 seconds to explore endless trading possibilities</p>
                        <Link to={"/register"} className="start" >Sign Up <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.75722 0.759766L18.8942 10.8968L16.722 13.069L8.75693 21.0341L6.58467 18.8619L13.2984 12.1481H0.440918V9.07611H12.729L6.58494 2.93201L8.75722 0.759766Z" fill="#fff" /></svg></Link>

                    </div>}
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Home;
