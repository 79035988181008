import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import './Login.scss'
import { useMediaQuery, useTheme } from '@mui/material';
import lightlogo from '../../Img/reglogo.png';
import lgleft from '../../Img/lgleft.png';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FormControl from '@mui/material/FormControl';
// import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import usercalls from "../../Axios";
import consts from '../../constant';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    // border: '2px solid #000',
    boxShadow: 24,
    borderRadius: '10px',
    outline: 'none',
    '@media(max-width:767.98px)': {
        width: '75%'
    },
    p: 4,
};


export default function SignInSide() {

    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const path = usercalls()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [load, setLoad] = useState(false)

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;
    const regex = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g

    // console.log(md, 'md');

    const navigate = useNavigate()

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const handleClickConfirmShowPassword = () => setShowConfirmPassword((show) => !show);

    const [confPassErr, setConfPassErr] = useState("")

    const checkconfirmpass = (e) => {
        if (password != e) {
            setConfPassErr("Password Mismatching")
        } else {
            setConfPassErr("")
        }

    }
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     console.log({
    //         email: data.get('email'),
    //         password: data.get('password'),
    //     });
    // };

    const handleSubmit = async () => {
        try {
            if (email && !regex.test(email)) {
                toast.error("Invalid Email Format")
            }
            else if (password && !passwordRegex.test(password)) {
                toast.error("Password Must Contain MIN 8 Character , One ALPHABET And Must Contain One Number and Special Characters  Eg : Abc@1234")
            }
            else if (email && password && confirmPassword == undefined) {
                toast.error('Please Re-Enter Password')
            }
            else if (confirmPassword !== password) {
                // toast.error('Re-Enter Password MisMatch')
                setConfPassErr("Password Mismatching")
            }
            else {
                setLoad(true)
                const url = `${consts.BackendUrl}/register`
                const payload = {
                    email: email,
                    password: password
                }
                const response = await path.postCallWithoutAuthToken({ url, payload });
                const result = await response.json();
                // console.log(result, 'result');
                if (result?.success) {
                    setLoad(false)
                    localStorage.setItem('email', result.result.email)
                    handleOpen()
                }
                else {
                    setLoad(false)
                    toast.error(result.message)
                }
            }
        }
        catch (error) {
            setLoad(false)
            // toast.error(error.response.data.message)
            console.log("🚀 ~ register ~ error:", error);

        }
    }

    useEffect(() => {
        console.log(window.localStorage.getItem('email'), "window.localStorage.getItem('email')")
        if (window.localStorage.getItem('email')) {
            setEmail(localStorage.getItem('email'))
            localStorage.removeItem('email')
        }
    }, [])

    return (
        <>
            <ToastContainer />
            <div className='register'>
                <Grid container component="main" sx={{ justifyContent: 'center' }} className={!md ? 'medium' : ''}>

                    {lg && <Grid
                        item
                        xs={false}
                        sm={false}
                        md={6}
                        lg={6}

                    >
                        <div className="lgleft">
                            <img src={lgleft} alt="lgleft" />
                        </div>
                    </Grid>}
                    <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
                        <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Box
                                    sx={{
                                        my: md && 3,
                                        mx: md && 8,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                        // boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                        padding: '28px 25px',
                                        borderRadius: '12px',
                                        width: md ? 'fit-content' : '85%'

                                    }}
                                    className={!md && 'log-bcg'}
                                >

                                    <div className='logo reg text-left'>

                                        <Link to="/"> <img src={lightlogo} /></Link>
                                    </div>
                                    <Typography component="h1" variant="h5" className='c-accout'>
                                        Create an account
                                    </Typography>
                                    <Box
                                        // component="form" noValidate
                                        // onSubmit={handleSubmit} 
                                        sx={{ mt: 1, width: !md ? '100%' : '350px' }}>
                                        <div className="email-fill">
                                            <div className="email-name">
                                                Email
                                            </div>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                // label="Email Address"
                                                name="email"
                                                autoComplete="email"
                                                autoFocus
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value) }}
                                            />
                                        </div>

                                        <div className="email-fill " style={{ marginTop: "10px" }} >
                                            <div className="email-name">
                                                Password
                                            </div>
                                            <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
                                                {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={password}
                                                    onChange={(e) => { setPassword(e.target.value) }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label={
                                                                    showPassword ? 'hide the password' : 'display the password'
                                                                }
                                                                onClick={handleClickShowPassword}

                                                                edge="end"
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                // label="Password"
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="email-fill" style={{ marginTop: "15px" }}>
                                            <div className="email-name">
                                                Re-Enter Password
                                            </div>
                                            <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
                                                {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    value={confirmPassword}
                                                    onChange={(e) => {
                                                        checkconfirmpass(e.target.value)
                                                        setConfirmPassword(e.target.value)
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label={
                                                                    showConfirmPassword ? 'hide the password' : 'display the password'
                                                                }
                                                                onClick={handleClickConfirmShowPassword}
                                                                edge="end"
                                                            >
                                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                // label="Password"
                                                />
                                            </FormControl>
                                            {confPassErr != "" && <div style={{ color: "red", textAlign: "center" }} >{confPassErr}</div>}
                                        </div>
                                        {/* <div className='display-1'>
                                            <FormControlLabel
                                                control={<Checkbox value="remember" color="primary" />}
                                                label="Remember me"
                                            />
                                            <Link href="#" variant="body2">
                                                Forgot password?
                                            </Link>
                                        </div> */}
                                        <div className="regbtn mt-10">
                                            {
                                                load ?
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        sx={{ mt: 3, mb: 2 }}
                                                    >
                                                        Processing...
                                                    </Button> :
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        sx={{ mt: 3, mb: 2 }}
                                                        // onClick={(() => { navigate('/register-success') })}
                                                        onClick={() => { handleSubmit() }}
                                                    >
                                                        Create account
                                                    </Button>
                                            }
                                        </div>
                                        <div className="conti mt-10">
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                            // onClick={(() => { navigate('/register-success') })}
                                            >
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_79_124)">
                                                        <path d="M19.8052 10.2303C19.8052 9.55056 19.7501 8.86711 19.6325 8.19836H10.2002V12.0492H15.6016C15.3775 13.2911 14.6573 14.3898 13.6027 15.0879V17.5866H16.8252C18.7176 15.8449 19.8052 13.2728 19.8052 10.2303Z" fill="#0177FE" />
                                                        <path d="M10.1999 20.0006C12.897 20.0006 15.1714 19.1151 16.8286 17.5865L13.6061 15.0879C12.7096 15.6979 11.5521 16.0433 10.2036 16.0433C7.59474 16.0433 5.38272 14.2832 4.58904 11.9169H1.26367V14.4927C2.96127 17.8695 6.41892 20.0006 10.1999 20.0006Z" fill="#34A853" />
                                                        <path d="M4.58564 11.9169C4.16676 10.675 4.16676 9.33014 4.58564 8.08818V5.51239H1.26395C-0.154389 8.33804 -0.154389 11.6671 1.26395 14.4927L4.58564 11.9169Z" fill="#FBBC04" />
                                                        <path d="M10.1999 3.95805C11.6256 3.936 13.0035 4.47247 14.036 5.45722L16.8911 2.60218C15.0833 0.904587 12.6838 -0.0287217 10.1999 0.000673888C6.41892 0.000673888 2.96126 2.13185 1.26367 5.51234L4.58537 8.08813C5.37537 5.71811 7.59106 3.95805 10.1999 3.95805Z" fill="#EA4335" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_79_124">
                                                            <rect width="20" height="20" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                                Continue with Google
                                            </Button>
                                        </div>


                                        <div className='text-center alraedy mt-10'>
                                            <Link to="/login" variant="body2">
                                                {"Already have an account? "}<span>Sign in</span>
                                            </Link>
                                        </div>


                                        {/* <Copyright sx={{ mt: 5 }} /> */}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Modal
                        open={open}
                        // onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className="text-right">
                                <HighlightOffIcon onClick={handleClose} />
                            </div>
                            <div className='c-accout text-center'>
                                Registered Successfully
                            </div>
                            <div className="alraedy mt-20">
                                Thankyou for registering with us.
                            </div>
                            <div className="alraedy mt-10">
                                Kindly Verify your email
                            </div>

                            <div className="regbtn mt-10">
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={(() => { navigate('/verify') })}
                                >
                                    Verify
                                </Button>
                            </div>
                        </Box>
                    </Modal>
                </Grid>
            </div >
        </>
    );
}